.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.scrollable-table {
  scroll-behavior: smooth;
}

.points-display {
  text-align: center;
  margin-top: 50px;
}

.points-count {
  font-size: 4rem;
  font-weight: bold;
  color: #ff6f61;
  margin-bottom: 20px;
}

/* Balloon animation */
.balloons-container {
  position: relative;
  height: 900px;
  overflow: hidden;
}

.balloon {
  position: absolute;
  bottom: -100px;
  width: 50px;
  height: 80px;
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 50%;
  animation: float 5s ease-in-out infinite;
}

.balloon:nth-child(2) {
  background-color: rgba(0, 255, 0, 0.7);
  animation-duration: 6s;
  left: 50px;
}

.balloon:nth-child(3) {
  background-color: rgba(0, 0, 255, 0.7);
  animation-duration: 4.5s;
  left: 100px;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

