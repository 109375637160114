/* styles.css or styles.scss */
:root {
    --primary-color: #16AEB4;  /* Your chosen colors */
    --secondary-color: #128286;
    --accent-color: #F25E36;
    --background-color: #2D3343;
    --text-color: #e4e4e4;
  }
  
  body {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
  }
  
  button.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  button.btn-success {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
  }
  
  .table-hover tbody tr:hover {
    background-color: var(--accent-color);
    color: white;
  }
  
  .ModalForm {
    background-color: var(--background-color) !important;
  }

  .navbar {
    background-color: var(--accent-color);
  }